<template>
    <v-container fluid>
        <v-card>
            <v-row>
                <v-toolbar dense
                           dark
                           max-height="50"
                           color="primary">

                    <v-btn icon
                           dark
                           @click="$router.go(-1)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <!--<v-toolbar-title v-if="gateInviteId > 0">{{gateInvite.name}}</v-toolbar-title>
                    <v-toolbar-title v-else>{{ $t('common_create') }}</v-toolbar-title>-->
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn v-if="gateInviteId == 0" dark
                               text
                               @click="saveGateInvite()">
                            {{ $t('common_save') }}
                        </v-btn>
                        <v-btn velse dark
                               text
                               @click="renewGateInvite()">
                            {{ $t('common_renew') }}
                        </v-btn>
                        <v-btn v-if="!gateInvite.sent" dark
                               text
                               @click="sendGateInvite()">
                            {{ $t('common_send') }}
                        </v-btn>
                        <v-btn v-else dark
                               text
                               @click="sendGateInvite()">
                            {{ $t('common_resend') }}
                        </v-btn>

                        <div class="text-center ml-5 mt-3" v-if="gateInviteId > 0">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs"
                                            v-on="on"
                                            dense>
                                        mdi-dots-vertical
                                    </v-icon>
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn color="error" tile @click="deleteDialog = !deleteDialog">
                                                <v-icon>
                                                    mdi-delete
                                                </v-icon>
                                                {{ $t('common_delete') }}
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="mt-3">
                    <v-text-field :disabled="gateInviteId > 0" v-model="gateInvite.email" :label="$t('common_email')"></v-text-field>
                    <v-text-field :disabled="gateInviteId > 0" v-model="gateInvite.phone" :label="$t('common_phone')"></v-text-field>
                    <v-text-field disabled v-model="gateInvite.code" :label="$t('common_code')"></v-text-field>
                    <v-text-field disabled v-model="gateInviteId" v-if="gateInviteId > 0"
                                  :label="$t('common_id')"></v-text-field>
                    <v-text-field v-model="gateInvite.createdOn" v-if="gateInviteId > 0"
                                  disabled
                                  :label="$t('common_creatededited')"></v-text-field>

                    <v-text-field v-model="gateInvite.validTo" v-if="gateInviteId > 0"
                                  disabled
                                  :label="$t('common_validto')"></v-text-field>

                    <v-text-field v-model="gateInvite.createdBy" v-if="gateInviteId > 0"
                                  disabled
                                  :label="$t('common_creatededitedby')"></v-text-field>

                    <v-text-field v-if="gateInvite.used"
                                  disabled
                                  :label="$t('common_used')"
                                  :value="$t('common_yes')"></v-text-field>
                    <v-text-field v-else
                                  disabled
                                  :label="$t('common_used')"
                                  :value="$t('common_no')"></v-text-field>

                    <v-text-field v-if="gateInvite.sent"
                                  disabled
                                  :label="$t('common_sent')"
                                  :value="$t('common_yes')"></v-text-field>
                    <v-text-field v-else
                                  disabled
                                  :label="$t('common_sent')"
                                  :value="$t('common_no')"></v-text-field>

                </v-card-text>
            </v-row>

            <v-row justify="center">
                <v-dialog v-model="deleteDialog"
                          max-width="320">
                    <v-card>
                        <v-card-title class="text-h5">
                            {{ $t('admin_dashboard_deletionapproval') }} {{gateInvite.name}}?
                        </v-card-title>
                        <!--<v-card-text></v-card-text>-->
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="error"
                                   @click="deleteGate()">
                                <v-icon right>
                                    mdi-delete
                                </v-icon>
                                {{ $t('common_delete') }}
                            </v-btn>
                            <v-btn color="primary"
                                   @click="deleteDialog = !deleteDialog">
                                {{ $t('common_cancel') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>


            <div style="flex: 1 1 auto;"></div>
        </v-card>
        <v-snackbar v-model="snackbar"
                    :timeout="snackbarTimeout">{{snackbarText}}</v-snackbar>
    </v-container>
</template>

<script>
    import gateInviteService from '@/services/gateInviteService';
    import router from '@/router';
    import moment from 'moment';

    export default {
        name: 'gateInviteEdit',
        props: ["gateInviteId", "gateId"],
        components: {
        },
        data: () => ({
            gateInvite: {
                id: 0,
                email: '',
                gateId: Number,
            },
            deleteDialog: false,
            snackbar: false,
            snackbarText: '',
            snackbarTimeout: 3000
        }),
        methods: {
            deleteGateInvite() {
                gateInviteService.deleteGateInvite(this.gateInvite.id)
                    .then(data => {
                        router.go(-1)
                    });
            },
            saveGateInvite() {
                debugger;
                this.gateInvite.gateId = this.gateId;
                gateInviteService.createGateInvite(this.gateInvite)
                    .then(resp => {
                        if (resp.data.success) {
                            this.snackbarText = resp.data.message;
                            this.snackbar = true;
                            router.push({ name: 'editGate', params: { gateId: parseInt(this.gateInvite.gateId) } });
                        }
                    });
            },
            renewGateInvite() {
                if (this.gateInvite.id > 0) {
                    gateInviteService.renewGateInvite(this.gateInvite)
                        .then(data => {
                            if (data.success) {
                                this.snackbarText = data.message;
                                this.snackbar = true;
                                this.fillView(data.gateInvite);
                            }
                            else {
                                this.snackbarText = data.message;
                                this.snackbar = true;
                            }
                        });
                }
            },
            sendGateInvite() {
                if (this.gateInvite.id > 0) {
                    gateInviteService.sendGateInvite(this.gateInvite.id)
                        .then(data => {
                            if (data.success) {
                                this.snackbarText = this.$t('gateinvite_sent_succes');
                                this.snackbar = true;
                                this.fillView(data.gateInvite);
                            }
                            else {
                                this.snackbarText = this.$t('gateinvite_sent_error');
                                this.snackbar = true;
                            }
                        });
                }
            },
            fillView(data) {
                this.gateInvite = data;
                this.gateInvite.createdOn = moment(data.createdOnUtc).format('YYYY-MM-DD HH:mm:ss');
                this.gateInvite.validTo = moment(data.validToUtc).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        mounted() {
            this.gateInvite.gateId = parseInt(this.gateId);
            if (this.gateInviteId > 0) {
                gateInviteService.getGateInvite(this.gateInviteId)
                    .then(data => {
                        this.fillView(data);
                    });
            }
        },
    }
</script>